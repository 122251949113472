// Bootstrap components
// require('./bootstrap/alert');
// require('./bootstrap/base-component');
// require('./bootstrap/button');
// require('./bootstrap/carousel');
require('./bootstrap/collapse');
// require('./bootstrap/modal');
// require('./bootstrap/offcanvas');
// require('./bootstrap/scrollspy');
require('./bootstrap/tab');
// require('./bootstrap/toast');

// Run `npm install --save @popperjs/core` from the project root to utilize the following bootstrap components
require('./bootstrap/dropdown');
// require('./bootstrap/popover');
// require('./bootstrap/tooltip');
