$(document).ready(function(){
    
    // Testimonials Slider
    $('.v-testimonial').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true
    });

    // Gallery SLider
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrow: true
    });

});