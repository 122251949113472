
$(document).ready(function(){

    var hamburger = document.querySelector(".hamburger");
    // On click
    hamburger.addEventListener("click", function() {
        // Toggle class "is-active"
        hamburger.classList.toggle("is-active");
        
        // Do something else, like open/close menu
        $('.left-nav').toggle();
        $('.right-nav').toggle();

    });

    $(".dropdown-parent").click(function(e){
        e.preventDefault();
        var thisMenu = $(this).closest('.dropdown-area').find('.dropdown');

        if (!thisMenu.is(':visible')) {
            $('.dropdown').hide();
        }
    
        $(this).toggleClass('over');
        thisMenu.toggle();

    });

});
