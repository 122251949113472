/**
 * App
 * ------
 * Specify vendor and app specific js files to be compiled
*/

// Bootstrap requires
require('./vendor/bootstrap-requires.js');

// Optional Vendor Requires
// require('./vendor/imagesloaded.pkgd.min.js');
// require('./vendor/jquery.actual.min');
// require('./vendor/jquery.validate.min');
// require('./vendor/object-visual-lock');
// require('./vendor/js.cookie');
require('./vendor/slick.min.js');
// require('./vendor/youtube-iframe-assistant');
// require('./vendor/picturefill');
// require('./vendor/waypoint');
// require('./vendor/waypoint-animate');

// Sliders
require('./app/sliders');

// ScrollTrigger
// require('./app/scrollTrigger);

// App Requires
// require('./app/config');
// require('./app/helpers');
require('./app/primary-navigation');
require('./app/filters');
